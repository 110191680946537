import React, { useRef, useState, useEffect } from "react";
import AdvanceReport from "./components/ScoreREports/AdvanceReport";
import ScoreHeader from "../accerts/svg/ScoreHeader.svg";
import footer from "../accerts/svg/footer.svg";
import { useParams } from "react-router-dom";
import BasicReport from "./components/ScoreREports/BasicReport";
import line from "../accerts/svg/reportspage/Line24.svg";
import ProgressBar from "./components/ScoreREports/ProgressBar";
import best from "../accerts/svg/reportspage/bestSub.svg";
import improve from "../accerts/svg/reportspage/improveSub.svg";
import CircleProgressBar from "./components/ScoreREports/circleProgressBar";
import loaderGif from "../accerts/gifs/LoaderGif.gif";
import Timer from "../accerts/gifs/timerGIf.gif";
import { CONFIG_VARS } from "./constants/configaration";
export const ScoreReports = () => {
  const { crnId, eventId } = useParams();
  const [reportData, setReportData] = useState(null);
  const [advanceReport, setAdvanceReport] = useState(null);
  const [progressordata, setProgressordata] = useState({});
  const [perfomence, setPerfomence] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState();
  const [advaceLoader, setAdvaceLoader] = useState(false);
  const hasCalledRef = useRef(false);
  const getSortedSectionNames = (data) => {
    if (!data?.advanced_report[0]?.sections) return [];
    const sortedSections = data?.advanced_report[0]?.sections?.sort(
      (a, b) => a.CORRECT - b.CORRECT
    );
    const sectionNames = sortedSections?.map((section) => section?.SECTION);
    setPerfomence(sectionNames);
  };
  const PostAdvancereport = async (eventId, crnId, reportType) => {
    setAdvaceLoader(true);
    try {
      const res = await fetch(
        `${CONFIG_VARS.baseUrl}?event_id=${eventId}&crn_id=${crnId}&event=${reportType}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Product-Id": "100",
            "Tenant-Id": "99999",
          },
          body: "", // Empty body as per your curl command
        }
      );
      let fetchReportsCounter = 0;
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      if (res.status === 201) {
        const data = await res.json();
        console.log(data);
        await delay(2000);
        fetchReports(crnId, eventId);
      } else if (res.status === 200) {
        if (fetchReportsCounter < 2) {
          fetchReports(crnId, eventId);
          fetchReportsCounter++;
        }
      }
    } catch (err) {
      console.error("Error creating advanced report:", err);
    } finally {
      setAdvaceLoader(false);
    }
  };
  const fetchReports = async (crnId, eventId) => {
    setLoader(true);
    const cacheBuster = new Date().getTime();
    const res = await fetch(
      `${CONFIG_VARS.S3buket}/${crnId}_${eventId}.json?cb=${cacheBuster}`
    );
    console.log(res.status);
    if (res?.status === 200) {
      const data = await res?.json();
      setReportData(data);
      setProgressordata(data?.basic_report?.section_wise_marks);

      const examSubmittedAt = new Date(data?.exam_submitted_at);
      const currentTime = new Date();
      const timeDifference = currentTime - examSubmittedAt;

      if (timeDifference > 2 * 60 * 60 * 1000) {
        if (!data?.advanced_report || data?.advanced_report.length === 0) {
          PostAdvancereport(eventId, crnId, "score");
        } else {
          setAdvanceReport(data?.advanced_report[0]);
          getSortedSectionNames(data);
        }
      }
      setLoader(false);
    } else {
      if (!hasCalledRef.current) {
        console.log(res.status);
        PostAdvancereport(eventId, crnId, "score");
        hasCalledRef.current = true;
      } else {
        setError("Reports are not available yet ..!");
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchReports(crnId, eventId);
  }, [crnId, eventId]);

  const formatDate = (dateString) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  const secondsToMinutes = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return `${minutes}m ${Math.round(formattedSeconds)}s`;
  };

  return (
    <>
      <div className="Rep_main_cont">
        <div className="Mathheader">
          <img style={{ width: "100%" }} src={ScoreHeader} alt="Header" />
          <p className="examName scoreExame">
            {reportData?.event_code} {reportData?.level}
          </p>
          <p className="studentName">{reportData?.first_name}</p>
          <p className="schoolid">{reportData?.school_name}</p>
          <p className="date">{formatDate(reportData?.exam_submitted_at)}</p>
          <p className="grade">{reportData?.grade}</p>
        </div>
        {!loader && !error ? (
          <div>
            {!advanceReport && (
              <>
                <BasicReport
                  reportData={reportData}
                  progressordata={progressordata}
                />
                {advaceLoader && (
                  <div className="Rep_timer-cont">
                    <h1>But Wait!</h1>
                    <p className="Rep_timer-des">
                      <span>We are generating a detailed report for you.</span>
                    </p>

                    <img src={Timer} alt="Timer" />
                  </div>
                )}
              </>
            )}
            {advanceReport && (
              <>
                <div>
                  <h3 className="Rep_sub-head">Overview</h3>
                  <div className="Rep_card-cont">
                    <div className="Rep_score_card">
                      <div>
                        <h4 className="Rep_score_cardHead">Score</h4>
                        <p>
                          {advanceReport?.exam_details[0]?.CURRENT_MARKS}
                          <span>
                            /
                            {advanceReport?.exam_details[0]?.MAX_MARKS_PER_EXAM}{" "}
                            marks
                          </span>
                        </p>
                      </div>
                      <img
                        className="Rep_score_cardLine"
                        src={line}
                        alt="line"
                      />
                      <div className="Rep_score_cardButtom">
                        <div>
                          <h4 className="Rep_score_cardHead">Accuracy</h4>
                          <p className="Rep_score_cardDes">
                            {Math.round(
                              advanceReport?.exam_details[0]?.ACCURACY * 10
                            ) / 10}
                            %
                          </p>
                        </div>
                        <div>
                          <h4 className="Rep_score_cardHead">Time Taken</h4>
                          <p>
                            {secondsToMinutes(
                              advanceReport?.exam_details[0]?.TOTAL_TIME_TAKEN
                            )}{" "}
                          </p>
                        </div>
                        <div>
                          <h4 className="Rep_score_cardHead">Total Time</h4>
                          <p>
                            {advanceReport?.exam_details[0]?.TOTAL_EXAM_TIME} M
                          </p>
                        </div>
                      </div>
                    </div>
                    <CircleProgressBar
                      totalMarks={
                        advanceReport?.exam_details[0]?.MAX_MARKS_PER_EXAM
                      }
                      correctMarks={
                        advanceReport?.exam_details[0]?.CURRENT_MARKS
                      }
                      incorrectMarks={advanceReport?.exam_details[0]?.INCORRECT}
                      unattempted={advanceReport?.exam_details[0]?.UNATTEMPTED}
                    />{" "}
                  </div>
                  <h3 className="Rep_sub-head">Subject Performance</h3>
                  <div className="perform_box">
                    <div>
                      <div className="perform_webflex">
                        <div>
                          <figure className="mb-0">
                            <img src={best} alt="subone" />
                          </figure>
                        </div>
                        <div className="perform_mobflex">
                          <h3 className="SCRPT-icnhdng no_mb">Best subject</h3>
                          <h4 className="SCRPT-cunt SCRPT-elips">
                            {perfomence[perfomence?.length - 1]}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="perform_webflex">
                        <div>
                          <figure className="mb-0">
                            <img src={improve} alt="subtwo" />
                          </figure>
                        </div>
                        <div className="perform_mobflex">
                          <h3 className="SCRPT-icnhdng no_mb">
                            Scope of improvement
                          </h3>
                          <h4 className="SCRPT-cunt SCRPT-elips">
                            {perfomence[0]}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Rep_Sub_cont">
                    <h3 className="Rep_graph_cardHead">Sectionwise Score</h3>
                    <div className="Rep_Sub_contIn">
                      {advanceReport?.sections?.map((subject, key) => (
                        <div key={key} className="Rep_Sub_cont-card">
                          <h3 className="Rep_Sub_contSubject">
                            {subject?.SECTION}
                          </h3>
                          <div className="Rep_progressor-cont">
                            <ProgressBar
                              progress={subject?.CORRECT}
                              total={subject?.TOTAL_MARKS}
                            />
                            <p>
                              {subject?.CORRECT}/{subject?.TOTAL_MARKS}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <AdvanceReport reportData={advanceReport?.sections} />
              </>
            )}
          </div>
        ) : (
          <div className="Loader">
            {!error ? (
              <>
                {" "}
                <img src={loaderGif} alt="loading" />
                <h4>Wait we are prepraing your reports....</h4>
              </>
            ) : (
              <div>{error}</div>
            )}
          </div>
        )}
        <img
          style={{ width: "100%", marginTop: "1rem" }}
          src={footer}
          alt="footer"
        />
      </div>
    </>
  );
};
